import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import LazyImgLoader from '../../../shared/ui/misc/LazyImgLoader';
import bannerImage from './banner-image.png';
import { ContainerColumn } from '../../../components/styled';
import { BodyLarge, BodyMedium } from '../../../components/styled/Typography';
const SupportBannerContainer = styled.div(props => ({
    height: props.isLoaded ? 222 : 0,
    maxWidth: 1320,
    width: '100%',
    position: 'relative',
    visibility: props.isLoaded ? 'visible' : 'hidden',
}));
const BannerBackground = styled.div(props => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 222,
    background: `no-repeat 20% 10% url(${props.url || ''})`,
    borderRadius: 12,
}));
const TextContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'isLoaded' })(props => ({
    position: 'relative',
    top: '-20px',
    height: props.isLoaded ? 'auto' : 0,
    padding: props.isLoaded ? `${props.theme.spacing_sizes.xl}px 0 
    ${props.theme.spacing_sizes.xl}px ${props.theme.spacing_sizes.s * 5}px` : 0,
    boxSizing: 'border-box',
}));
const BannerTitle = styled(BodyLarge)(props => ({
    fontSize: 42,
    fontWeight: 700,
    lineHeight: '51px',
    color: props.theme.palette.text.disabled,
    marginBottom: props.isLoaded ? props.theme.spacing_sizes.m : 0,
}));
const BannerText = styled(BodyMedium)(props => ({
    color: props.theme.palette.text.disabled,
    maxWidth: 511,
    lineHeight: '32px',
    fontSize: 18,
}));
const SupportBanner = () => {
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(false);
    const handleLoadSuccess = () => setIsLoaded(true);
    return (_jsxs(SupportBannerContainer, { isLoaded: isLoaded, children: [_jsx(LazyImgLoader, { url: bannerImage, onLoadSuccess: handleLoadSuccess }), _jsx(BannerBackground, { url: bannerImage }), _jsxs(TextContainer, { isLoaded: isLoaded, children: [_jsx(BannerTitle, { children: t('pages.support', { ns: 'common' }) }), _jsx(BannerText, { children: t('support.support_banner_text') })] })] }));
};
export default SupportBanner;
